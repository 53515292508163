.adowl-system-errors .alert {
    margin: 15px 0;
}

.adowl-default .block-title {
    margin-top: 20px;
}

.adowl-default .block-title h2, 
.adowl-default .block-title h3, 
.adowl-default .block-title h4, 
.adowl-default .block-title h5 {
    margin-bottom: 0;
}

.adowl-default .jumbotron {
    padding: 10px 20px;
    margin: 0 -15px;
    box-shadow: none;
    border-radius: 0;
}

.adowl-default .jumbotron h1 {
    font-size: 40px;
    margin-top: 10px;
}

.adowl-loading .adowl-form {
    opacity: 0.1;
}

.adowl-saving {
    position: fixed;
    left: 45%;
    top: 45%;
    font-size: 40px;
    display: none;
}

.adowl-loading .adowl-saving {
    display: block;
}


/* adowl step nav */
.adowl-steps {
    color: rgba(0,0,0,.4);
    font-size: 15px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
}

.adowl-steps ul {
    margin-bottom: 5px;
}

.adowl-steps li a {
    cursor: pointer;
}

.adowl-steps .active span {
    color: rgba(0,0,0,.8);
    font-weight: bold;
}

.adowl-steps .tnt-angle-right {
    margin-left: 5px;
    color: rgba(0,0,0,.2);
}

/* adowl progress bar */
.adowl-progress {
    height: 8px;
    border-radius: 0;
    margin: 1px -15px;
}

.adowl-progress .progress-bar.progress-bar-info {
    background-color: rgba(0,0,0,.3);
}

/* adowl welcome */
.adowl-welcome,
.adowl-new-ad {
    margin-top: 15px;
}

.adowl-info-well {
    background-color: transparent;
}

.adowl-info-well h3 {
    margin-top: 0;
}

/* adowl ad progress */
#preview-ad-btn {
    border-radius: 0;
}

.adowl-your-ad-container {
    padding: 5px 20px;
    margin-right: -15px;
    border-left: 2px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.adowl-discount-information {
    padding-bottom: 5px;    
}

.adowl-default .total {
    font-size: 32px;
    line-height: 65px;
}

.adowl-default .mobile-total {
    font-size: 30px;
}

.adowl-your-ad-section-title {
    margin-bottom: 10px;
}

.adowl-your-ad-container h4 {
    margin: 0;
}

.adowl-your-ad-container .tnt-edit {
    font-size: 18px;
}

.adowl-your-ad-container .list-unstyled {
    margin-bottom: 20px;
    line-height: 24px;
}

.adowl-your-ad-container .tnt-angle-right {
    color: rgba(0,0,0,.3);
}

.adowl-your-ad-section .adowl-your-ad-label {
    font-weight: bold;
}

.adowl-print-ad-preview {
    margin-right: -15px;
    margin-bottom: 0;
}

.adowl-checkbox-group {
    max-height: 250px;
    overflow: auto;
}

/* adowl categories */

.adowl-category-container .fa-nest {
    pointer-events: none;
}

.adowl-parent-categories {
    margin-left: 30px;
    margin-top: -10px;
}

.adowl-parent-categories .adowl-category-panel {
    border-radius: 0;
    box-shadow: none;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.05);
    margin-top: 0!important;
}

.adowl-parent-categories .adowl-category-panel:first-child {
    border: 0;
}

.adowl-parent-categories .tnt-square-full,
.adowl-parent-categories .tnt-check-square,
.adowl-package .tnt-square-full,
.adowl-package .tnt-check-square {
    color: rgba(0,0,0,.01);
    margin-right: 5px;
    text-shadow: 1px 1px rgba(255,255,255,.8), -1px -1px rgba(0,0,0,.15);
    box-shadow: inset 3px 3px 8px rgba(0,0,0,.15);
}

.adowl-parent-categories a:hover .tnt-square-full,
.adowl-parent-categories a:hover .tnt-check-square,
.adowl-package:hover .tnt-square-full,
.adowl-package:hover .tnt-check-square {
    color: rgba(0,0,0,.08);
}

.adowl-parent-categories .active .tnt-check-square,
.adowl-package.active .tnt-check-square {
    color: rgba(255,255,255,.8);
}

.adowl-parent-categories a:hover .tnt-check-square,
.adowl-package:hover .tnt-check-square {
    color: rgba(255,255,255,.6);
}

.adowl-parent-categories .adowl-parent-category {
    background-color: transparent;
    background-image: none;
    padding: 0;
}

.adowl-parent-categories .adowl-parent-category a {
    display: block;
    padding: 10px 5px;
    text-decoration: none;
}

.adowl-parent-categories .adowl-parent-category a.active {
    background-color: #305b80;
    color: #fff;
    padding: 10px 15px;
    font-weight: bold;
}

.adowl-parent-categories .adowl-parent-category .parent-title,
.adowl-parent-categories .adowl-parent-category .tnt-svg {
    font-size: 18px;
}

.adowl-parent-categories .adowl-parent-category .tnt-svg {
    margin-right: 10px;
}

.adowl-parent-categories .adowl-parent-category .parent-description {
    color: rgba(0,0,0,.6);
    display: block;
    margin-left: 28px;
}

.adowl-parent-categories .adowl-parent-category .select-sub-category {
    display: block;
    margin-left: 28px;
    display: none;
    font-weight: normal;
}

.adowl-parent-categories .adowl-parent-category .select-sub-category small {
    color: rgba(255,255,255,.5);
}

.adowl-parent-categories .adowl-parent-category a.active .parent-description {
    display: none;
}

.adowl-parent-categories .adowl-parent-category a.active .select-sub-category {
    display: block;
}

.adowl-parent-categories .adowl-child-catgories {
    margin: 0;
    background: rgba(0,0,0,0.02);
}

.adowl-parent-categories .adowl-child-catgories .child-category-title {
    background-color: rgba(0,0,0,.02);
}

.adowl-parent-categories .adowl-child-catgories .child-category {
    border: 0;
    background-color: transparent;
}

.adowl-parent-categories .adowl-child-catgories .child-category.active,
.adowl-parent-categories .adowl-child-catgories .child-category.active:hover,
.adowl-parent-categories .adowl-child-catgories .child-category.active:focus {
    background-color: #d9edf7;
    color: rgba(0,0,0,.7);
    font-weight: bold;
}

.adowl-parent-categories .adowl-child-catgories .child-category.active .tnt-svg {
    color: rgba(0,0,0,.8);
}

.adowl-parent-categories .adowl-child-catgories .child-category .skip-sub-category {
    font-weight: bold;
}

.adowl-default .tnt-asterisk {
    font-size: 9px;
}

.adowl-default .adowl-form-error {
    padding-top: 5px;
    font-weight: normal;
}

.adowl-default .form-control.adowl-form-error {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.adowl-default .form-control.adowl-form-error:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

/* packages */
.adowl-package {
    margin-bottom: 10px;
    border-color: rgba(0,0,0,.1);
    cursor: pointer;
}

.adowl-package.active {
    background-color: rgba(0,0,0,0.03);
}

.adowl-package .panel-heading {
    background-color: rgba(0,0,0,.02);
    border-color: rgba(0,0,0,.08);
}

.adowl-package .panel-heading h3 {
    font-size: 18px;
    margin: 0;
}

.adowl-package .panel-heading h3 a {
    text-decoration: none;
}

.adowl-package .panel-heading .tnt-svg {
    font-size: 18px;
    float: left;
    margin-right: 10px;
}

.adowl-package.active .panel-heading {
    background-color: #305b80;
    color: #fff;
}

.adowl-package.active .panel-heading h3 a,
.adowl-package.active .panel-heading h3 a:hover {
    color: #fff;
}

/* package discount */
.adowl-packages .adowl-discount { 
    padding: 10px; 
    background-color: rgba(219, 234, 212, 0.5);
    border-top: 2px solid rgba(156, 177, 149, 0.17);
}

.adowl-packages .discount-tag { 
    opacity: 0.4;
    margin-right: 5px; 
} 

.adowl-packages .adowl-discount .name { 
    font-size: 16px; 
    font-weight: bold; 
}

.adowl-packages .adowl-discount .seperator { 
    opacity: 0.3; 
    padding: 0 2px; 
    display: inline-block; 
}

.adowl-packages .adowl-discount .badge {
    font-size: 16px;
    font-weight: bold;
    background-color: #3c763d;
    padding: 6px 12px;
    margin-right: 5px;
    margin-bottom: 5px;
}
    
/* datepicker changes */
.datepicker-days .day {
    color: #3276b1;
    font-weight: bold;
}

.datepicker-days .day.single-month,
.datepicker-days .day.single-month:hover,
.datepicker-days .day.disabled,
.datepicker-days .day.disabled:hover {
    font-weight: normal;
}

.adowl-main-container .form-control[readonly] {
    cursor: pointer;
}

/* counter & upgrade */
.adowl-character-counter {
    padding-top: 5px;
}

#adowl-show-upgrades {
    display: none;
}

#adowl-show-upgrades .btn {
    margin-right: 5px;
}

/* upsells */
label.adowl-upsell-title {
    font-weight: bold;
}

.adowl-upsell-description {
    margin: 0;
    padding-left: 20px;
}

.adowl-upsell-checkbox.checkbox {
    border-top: 1px solid rgba(0,0,0,.05);
    padding: 10px 0;
    min-height: 40px;
}

.adowl-upsell-checkbox:first-child {
    border: 0;
}

.adowl-upsell-options {
    display: none;
}

.adowl-upsell-options.open {
    display: block;
}

.adowl-change-stock-image {
    cursor: pointer;
}

.adowl-change-stock-image:hover {
    background-color: rgba(0,0,0,.1);
}

.adowl-change-stock-image.active {
    background-color: #dff0d8;
}

.adowl-stock-image {
    background-repeat: no-repeat;
    background-size: contain;
    height: 40px;
    margin: 10px 5px;
    background-position: center center;
}

.adowl-upsell-image-file {
    width: 75px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 0;
}

.adowl-online-images {
    margin-top: 10px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.adowl-online-images .thumbnail {
    border: 1px solid rgba(0,0,0,.1);
    padding: 5px;
}

.adowl-online-images .thumbnail a {
    height: 100px;
    overflow: hidden;
    display: block;
}

.adowl-online-images .thumbnail label {
    margin-left: 2px;
}

.adowl-online-images .modal .btn-danger {
    margin-left: 20px;
}

.adowl-live-preview {
    text-align: center;
}

.adowl-live-preview img {
    display: inline-block!important;
    margin-top: 5px;
}

/* finish step */
.adowl-order-details {
    background-color: transparent;
    margin-top: 18px;
}

.adowl-order-details .adowl-order-summary .block-title .block-title-inner {
    border-color: transparent;
}

.adowl-order-details .adowl-order-summary .block-title {
    border-bottom: 1px solid rgba(0,0,0,.05);
}

.adowl-order-details .adowl-live-preview {
    text-align: left;
}

.adowl-order-details .total {
    font-size: 24px;
}

.adowl-order-details .adowl-finish-details-header {
    margin-top: 5px;
    border-bottom-width: 3px;
}

.adowl-order-details .block-title h4 {
    font-size: 14px;
}

.adowl-billing-ref { 
    color: #333; 
}

.adowl-billing-ref.checked {
    font-weight: bold; 
}

/* tn payfield exp */
.tnpay-expiration-wrapper select {
    width: 48%;
    display: inline-block;
}

.tnpay-expiration-wrapper .tnpay-month {
    margin-right: 2%;
}

.tnpay-field-wrapper iframe {
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    display: inline-block;
    height: 34px;
    line-height: 1.42857;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.adowl-school .block-title-inner,
.adowl-special_events .block-title-inner{
    margin-bottom: 20px;
}

/* mobile */
@media screen and (max-width: 991px) {
    .ad-details-open #adowl-your-ad-details {
        position: fixed;
        display: block!important;
        top: 0;
        left: 0;
        background-color: #fff;
        width: 100%;
        padding: 0;
        z-index: 100;
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        border: 10px solid rgba(0,0,0,.8);
    }

    .modal-open #adowl-your-ad-details {
        display: block!important;
        height: 1px;
    }

    .modal-open #adowl-your-ad-details .adowl-your-ad-container,
    .modal-open #preview-ad-btn,
    .modal-open #adowl-your-ad-mobile-close {
        display: none;
    }

    #adowl-your-ad-mobile-close {
        margin-top: 15px;
        margin-right: 10px;
    }

    .adowl-print-ad-preview {
        margin-top: 50px;
        margin-bottom: -50px;
        margin-right: 0;
    }

    .adowl-your-ad-container {
        margin-top: 50px;
    }

    .adowl-default .jumbotron h1 {
        font-size: 36px;
    }

    .adowl-your-ad-container {
        margin-right: 0;
        border: 0;
    }
}

@media screen and (max-width: 767px) {
    .adowl-default .jumbotron {
        padding: 10px;
    }

    .adowl-default .jumbotron h1 {
        font-size: 24px;
    }

    .adowl-steps {
        font-size: 11px;
        margin-right: -5px;
    }

    .adowl-steps .list-inline>li {
        padding-left: 3px;
        padding-right: 3px;
    }

    .block-title.adowl-step-title h2 {
        font-size: 20px;
    }

    .adowl-parent-categories {
        margin-left: 0px;
    }

    .adowl-package .panel-heading h3 {
        font-size: 16px;
    }
}
